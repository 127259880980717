import React from 'react';
import { Button } from '@mui/material'; // Ensure you're using Material UI

const PricingButton = () => {
    const handlePricingClick = () => {
        window.scrollTo(0, 0); // Scroll to top of the page
        window.location.href = '/pricing'; // Navigate to Pricing page
    };

    return (
        <div  style={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}>
        <Button
            variant="contained"
            color="primary"
            onClick={handlePricingClick}
            className="matButton"
            style={{  width: '80%', maxWidth:'600px', marginTop: '2%', borderRadius:'7.5px', padding:'.75%', fontFamily:'MyCustomFontSemi', fontSize:'1.25rem' }}
        >
            GET YOURS!
        </Button>
        </div>
    );
};

export default PricingButton;
