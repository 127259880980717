import React from 'react';
import './CenteredDiv.css'; // Ensure you have the CSS for styling
import cam from './cam.png'; // Assuming cam.png is in the same directory

const CenteredDiv = () => {
  return (
    <div className="centered-container">
      <img src={cam} alt="Camera" className="centered-image" />
      <div className="centered-text">Want a unqiue avatar instead? Use our tablet's built in camera and take a live picture for that game!</div>
    </div>
  );
};

export default CenteredDiv;
