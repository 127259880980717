import React, { useEffect, useState } from 'react';
import './ImageLoop.css'; // Ensure you have the CSS for styling
import CenteredDiv from './CenteredDiv';
import CenteredDiv3 from './CenteredDiv3';
import CenteredDiv2 from './centeredDiv2';
import { Button, Divider } from '@mui/material'; // Importing Divider from Material UI
import { useNavigate } from 'react-router-dom';
// Import all images from the src folder
import a1 from './a1.png';
import a2 from './a2.png';
import a3 from './a3.png';
import a4 from './a4.png';
import a5 from './a5.png';
import a6 from './a6.png';
import a7 from './a7.png';
import a8 from './a8.png';
import a9 from './a9.png';
import a10 from './a10.png';
import a11 from './a11.png';
import a12 from './a12.png';
import a13 from './a13.png';
import a14 from './a14.png';
import a15 from './a15.png';
import a16 from './a16.png';
import a17 from './a17.png';
import a18 from './a18.png';
import a19 from './a19.png';
import a20 from './a20.png';
import PricingButton from './PricingButton'; // Adjust the path as necessary
import './index.css';


const images = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13, a14, a15, a16, a17, a18, a19, a20];

// Create a larger array of images (1000 images in total)
const extendedImages = Array.from({ length: 1000 }, (_, index) => {
    return images[index % images.length]; // Loop through the original images
});



const Home = () => {
    const [currentIndex1, setCurrentIndex1] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(9); // Offset for the second row
    const [currentIndex3, setCurrentIndex3] = useState(10); // Offset for the third row
    const [imagesToShow, setImagesToShow] = useState(10); // Default to 8 images for larger screens

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/pricing'); // Navigate to the Pricing page
    };

    useEffect(() => {
        const updateImagesToShow = () => {
            // Set the number of images based on window width
            if (window.innerWidth < 768) {
                setImagesToShow(6); // Show 4 images on mobile
            } else {
                setImagesToShow(10); // Show 8 images on larger screens
            }
        };

        // Initial check
        updateImagesToShow();

        // Add event listener for window resize
        window.addEventListener('resize', updateImagesToShow);

        // Clean up the event listener on unmount
        return () => window.removeEventListener('resize', updateImagesToShow);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex1((prevIndex) => (prevIndex + 1) % extendedImages.length);
            setCurrentIndex2((prevIndex) => (prevIndex + 1) % extendedImages.length);
            setCurrentIndex3((prevIndex) => (prevIndex + 1) % extendedImages.length);
        }, 1100); // Change images every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    const displayedImages1 = Array.from({ length: imagesToShow }, (_, index) => {
        const imageIndex = (currentIndex1 + index) % extendedImages.length; // Loop through extended images
        return extendedImages[imageIndex];
    });

    const displayedImages2 = Array.from({ length: imagesToShow }, (_, index) => {
        const imageIndex = (currentIndex2 + index) % extendedImages.length; // Loop through extended images
        return extendedImages[imageIndex];
    });

    const displayedImages3 = Array.from({ length: imagesToShow }, (_, index) => {
        const imageIndex = (currentIndex3 + index) % extendedImages.length; // Loop through extended images
        return extendedImages[imageIndex];
    });

    return (
        <div>

<div className="homeContainer">
        <div className="centeredHeader">
          <p className="headerLabel">THE BEST ASPECTS OF CLASSIC BAR DARTS COMBINED WITH MODERN TECHNOLOGY</p>
        </div>

        {/* Features text with the new header and buttons */}
       

        {/* Display images based on selected feature */}


       
    
      </div>
            <div className="titleText">Personalized Avatars</div> {/* Grey rectangle */}

            
            
            <div className="image-loop-container">

                <div className="overlay" /> {/* Overlay div */}

                <div className="image-row">
                    {displayedImages1.map((img, index) => (
                        <div key={index} className="image-wrapper">
                            <img
                                src={img}
                                alt={`Image ${images.indexOf(img) + 1}`}
                                className="loop-image"
                            />
                        </div>
                    ))}
                </div>
                
                {/* <div className="image-row">
          {displayedImages2.map((img, index) => (
            <div key={index} className="image-wrapper">
              <img
                src={img}
                alt={`Image ${images.indexOf(img) + 1}`}
                className="loop-image"
              />
            </div>
          ))}
        </div> */}
                <div className="image-row">
                    {displayedImages3.map((img, index) => (
                        <div key={index} className="image-wrapper">
                            <img
                                src={img}
                                alt={`Image ${images.indexOf(img) + 1}`}
                                className="loop-image"
                            />
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className="text-rectangle">Unique Player Avatars</div> */}

            <div className="text-rectangle2">Custom Avatars</div> {/* Grey rectangle */}

            <CenteredDiv></CenteredDiv>

            <div className="text-rectangle2">Quality Materials</div> {/* Grey rectangle */}

            <div className="text-rectangle3">Our dedication to quality will ensure this product will look great in any room you decide to place it in!</div> {/* Grey rectangle */}
            <CenteredDiv3></CenteredDiv3>

            <div className="text-rectangle2">Stat Tracking</div> {/* Grey rectangle */}

            <CenteredDiv2></CenteredDiv2>

            <div className="text-rectangle2">Free Updates</div> {/* Grey rectangle */}
            <div className="text-rectangle3" style={{marginBottom: "3%"}}>Your purchase automatically gives you access to all future updates and features our team develops!</div> {/* Grey rectangle */}

            <PricingButton />
        </div>


    );
};

export default Home;
