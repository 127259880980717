import React, { useEffect } from 'react';
import './relief.css';
import PricingButton from './PricingButton'; // Adjust the path as necessary

const Relief = () => {
    useEffect(() => {
        // Trigger Facebook Pixel PageView event
        if (window.fbq) {
            window.fbq('track', 'PageView');
        }
    }, []);  // Empty dependency array means this runs once when the component mounts

    return (
        <div className="background-image">
            <div className="relief-container">
                <div className="card">
                    {/* Text above the images */}
                    <div className="text-above">
                        <p className="themedTitle">
                            Proudly Tampa Bay Based
                        </p>

                        <p className="themedText">
                            As a Tampa based company, we have directly seen the impact of the recent hurricane season on our communities. That is why we are now donating 10% of proceeds to help people in need
                        </p>

                        {/* <p className="themedText2">
                            Just use code <span className="highlightText">"RELIEF"</span> at checkout!!
                        </p> */}
                    </div>

                    <p className="themedText3">
                        Your purchase will directly help one of the following
                    </p>

                    {/* Image container */}
                    <div className="relief-images">
                        <img src={require('./help1.png')} alt="Help 1" className="relief-image" />
                        <img src={require('./help2.png')} alt="Help 2" className="relief-image" />
                        <img src={require('./help3.png')} alt="Help 3" className="relief-image" />
                    </div>
                </div>
            </div>
            {/* Pricing button, temporarily removed as requested */}
            {/* <PricingButton /> */}
            <PricingButton />
        </div>
    );
};

export default Relief;
