import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Pricing from './Pricing';
import HomeMenu from './gameSelect';
import Contact from './Contact';
import Cane from './Cane';
import './index.css'; // Import the CSS file
import ScrollToTop from './ScrollToTop'; // Adjust the path as necessary
import Relief from './TampaStrong';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
    
        <Route path="/" element={<Home />} />
        <Route path="/validUser" element={<HomeMenu />} />
       
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/hurricaneRelief" element={<Relief />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/websites" element={<Websites/>} />
        <Route path="/client-portal" element={<ClientPortal />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
