// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import getFirestore
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA5lKNbGvTYmAZY_eqTWNB1fnfk9Ks3naY",
  authDomain: "scorekeeperplus-cb310.firebaseapp.com",
  projectId: "scorekeeperplus-cb310",
  storageBucket: "scorekeeperplus-cb310.firebasestorage.app",
  messagingSenderId: "949660152476",
  appId: "1:949660152476:web:2716363e27b24e1b2db359",
  measurementId: "G-NVC5Z2T9P9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };