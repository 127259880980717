// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate, useLocation } from 'react-router-dom';
// import { Select, MenuItem, FormControl } from '@mui/material';
// import FavoriteIcon from '@mui/icons-material/Favorite'; // Import the heart icon
// import './Navbar.css';
// import logo from './lgNav.png';

// const Navbar = () => {
//   const [isMobile, setIsMobile] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [selectedOption, setSelectedOption] = useState('/');

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener('resize', handleResize);
//     handleResize();

//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   useEffect(() => {
//     setSelectedOption(location.pathname);
//   }, [location]);

//   const handleSelectChange = (e) => {
//     setSelectedOption(e.target.value);
//     navigate(e.target.value);
//   };

//   return (
//     <nav className="navbar">
//       <div className="navbar-logo">
//         <Link to="/">
//           <img src={logo} alt="Logo" className="logo-img" />
//         </Link>
//       </div>

//       {isMobile ? (
//         <FormControl variant="outlined" className="navbar-dropdown" style={{ marginTop: '2%' }}>
//           <Select
//             value={selectedOption}
//             onChange={handleSelectChange}
//             displayEmpty
//             sx={{
//               width: '100%',
//               height: '45px',
//               color: 'white',
//               backgroundColor: 'rgba(128, 128, 128, 0.3)',
//               '& .MuiSelect-select': {
//                 display: 'flex',
//                 justifyContent: 'flex-start',
//                 alignItems: 'center',
//                 color: 'white',
//               },
//               '& .MuiOutlinedInput-notchedOutline': {
//                 borderColor: 'white',
//               },
//               '&:hover .MuiOutlinedInput-notchedOutline': {
//                 borderColor: 'white',
//               },
//               '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                 borderColor: 'white',
//               },
//             }}
//           >
//             <MenuItem value="/" style={{ textAlign: 'center', color: 'black' }}>Home</MenuItem>
//             <MenuItem value="/pricing" style={{ textAlign: 'center', color: 'black' }}>Pricing</MenuItem>
//             <MenuItem value="/helpTampa" style={{ textAlign: 'center', color: 'black' }}>
//               Helping Tampa Bay<FavoriteIcon style={{ marginLeft: '8px', color: 'red' }} />
//             </MenuItem>
//             <MenuItem value="/contact" style={{ textAlign: 'center', color: 'black' }}>Contact</MenuItem>
//           </Select>
//         </FormControl>
//       ) : (
//         <ul className="navbar-links">
//           <li><Link to="/">Home</Link></li>
//           <li><Link to="/pricing">Pricing</Link></li>
//           <li>
//             <Link to="/helpTampa">
//               Hurricane Relief <FavoriteIcon style={{ marginBottom:'-5px', marginLeft: '5px', color: 'red' }} />
//             </Link>
//           </li>
//           <li><Link to="/contact">Contact</Link></li>
//         </ul>
//       )}
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Select, MenuItem, FormControl } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite'; // Import the heart icon
import './Navbar.css';
import logo from './lgNav.png';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState('/');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setSelectedOption(location.pathname);
  }, [location]);

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    navigate(e.target.value);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo-img" />
        </Link>
      </div>

      {isMobile ? (
        <FormControl variant="outlined" className="navbar-dropdown" style={{ marginTop: '2%' }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            sx={{
              width: '100%',
              height: '45px',
              color: 'white',
              backgroundColor: 'rgba(128, 128, 128, 0.3)',
              '& .MuiSelect-select': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                color: 'white',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
            }}
          >
            <MenuItem value="/" style={{ textAlign: 'center', color: 'black' }}>Home</MenuItem>
            <MenuItem value="/pricing" style={{ textAlign: 'center', color: 'black' }}>Pricing</MenuItem>
            <MenuItem value="/contact" style={{ textAlign: 'center', color: 'black' }}>Contact</MenuItem>
            
            <MenuItem value="/hurricaneRelief" style={{ textAlign: 'center', color: 'black' }}>Hurricane Relief Fund<FavoriteIcon style={{ marginLeft: '8px', color: 'red' }} />
            </MenuItem>
          </Select>
        </FormControl>
      ) : (
        <ul className="navbar-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/pricing">Pricing</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/hurricaneRelief">Hurricane Relief Fund<FavoriteIcon style={{ marginBottom:'-5px', marginLeft: '5px', color: 'red' }} />
          
          </Link></li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
