import React from 'react';
import { Button } from '@mui/material'; // Ensure you're using Material UI
import './ImageLoop.css';

const WhyButton = () => {
    const handlePricingClick = () => {
        window.scrollTo(0, 0); // Scroll to top of the page
        window.location.href = '/'; // Navigate to Pricing page
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handlePricingClick}
            style={{ margin: '10%', maxWidth:'700px', width: '80%', marginTop: '1%', fontFamily:'MyCustomFontSemi', fontSize:'.9rem' }}
        >
           Why CHOOSE SCOREKEEPER PLUS?
        </Button>

        

    );
};

export default WhyButton;
