import React from 'react';
import './CenteredDiv.css'; // Ensure you have the CSS for styling
import cam from './avg.png'; // Assuming cam.png is in the same directory

const CenteredDiv2 = () => {
  return (
    <div className="centered-container">
      <img src={cam} alt="Camera" className="centered-image2" />
      <div className="centered-text">All game modes are equipped with stat tracking to make the game more engaging and to help you improve your skills!</div>
    </div>
  );
};

export default CenteredDiv2;
